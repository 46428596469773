<template>
  <main class="kb-main" id="kb-educourse">
    <lxp-main-header
        :show-title="true"
        :show-breadcrumb="true"
        title="연수신청">
      <template v-slot:inner>
        <div class="header-column">
          <div class="download">
            <file-down-link :file-key="guideFileKey" />
            <file-down-link :file-key="mngrFileKey" />
            <a href="javascript:" class="download-link" @click="goTest">사전테스트<i class="icon-arrow-next"></i></a>
          </div>
        </div>
      </template>
    </lxp-main-header>
    <div class="main-content main-component">
      <div class="educourse-list-container">
        <!-- list-top -->
        <article class="content-section section-spacing">
          <header class="section-header" style="justify-content:flex-end">
            <div class="kb-form-dropdown download-dropdown" :class="{'is-active': showAttachments}" data-offset="bottom-right" @click="showAttachments = !showAttachments">
              <button class="kb-form-dropdown-btn kb-btn kb-btn-secondary kb-btn-sm rounded-lg"><i class="icon-download32"></i><span class="text">KB 디지털 문해력 교재</span></button>
              <div class="kb-form-dropdown-options">
                <div class="dropdown-option">
                  <ul class="dropdown-option-list" v-for="(file,idx) in files" :key="idx">
                    <li class="dropdown-option-item">
                      <a class="dropdown-option-link" :title="file.fileNm" @click="downloadAtch(file)">
                        <span type="button" class="dropdown-option-text">{{ (file.fileNm != null ? file.fileNm : '-') }}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </header>
          <header class="section-header">
            <h4 class="title">연수현황</h4>
          </header>
          <div class="kb-table kb-table-bordered-v2">
            <table>
              <colgroup>
              </colgroup>
              <thead>
              <tr>
                <th><span class="th-title">필수/희망</span></th>
                <th><span class="th-title">부점구분</span></th>
                <th><span class="th-title">기본대상</span></th>
                <th><span class="th-title">기본완료</span></th>
                <th><span class="th-title">심화대상</span></th>
                <th><span class="th-title">심화완료</span></th>
                <th><span class="th-title">최종수료</span></th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td><strong class="td-text">{{ dtlItem && dtlItem.reqYn === 'Y' ? '필수' : '희망' }}</strong></td>
                <td><strong class="td-text">{{ dtlItem && dtlItem.digiDeptTyNm ? dtlItem.digiDeptTyNm : '-' }}</strong></td>
                <td>
                  <template v-if="dtlItem && dtlItem.basicYn">
                    <strong class="td-text" v-html="getDigitalFinishText('trgt',dtlItem.basicYn)"></strong>
                    <div v-if="nowbasicbgngdt != ''" class="td-text">
                      ({{ nowbasicbgngdt }} 예정)
                    </div>
                  </template>
                  <template v-else>
                    -
                  </template>
                </td>
                <td>
                  <template v-if="basicLrn != '-'">
                    <strong class="td-text" v-html="getDigitalFinishText('fnsh',basicLrn)"></strong>
                  </template>
                  <template v-else>
                    -
                  </template>
                </td>
                <td>
                  <template v-if="dtlItem && dtlItem.advnYn">
                    <strong class="td-text" v-html="getDigitalFinishText('trgt',dtlItem.advnYn)"></strong>
                    <div v-if="nowadvancedbgngdt != ''" class="td-text">
                      ({{ nowadvancedbgngdt }} 예정)
                    </div>
                  </template>
                  <template v-else>
                    -
                  </template>
                </td>
                <td>
                  <template v-if="advanLrn != '-'">
                    <strong  v-html="getDigitalFinishText('fnsh',advanLrn)"></strong>
                  </template>
                  <template v-else>
                    -
                  </template>
                </td>
                <td>
                  <template v-if="finalLrn != '-'">
                    <strong  v-html="getDigitalFinishText('fnsh',finalLrn)"></strong>
                  </template>
                  <template v-else>
                    -
                  </template>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="d-flex justify-content-end mt-2 text-muted">
            ※개인별 수료여부는 각 회차 종료 후 약 10영업일 후에 반영될 예정입니다.
          </div>
        </article>

        <div class="educourse-summary course-row" style="border-top: none">
          <article class="course-card">
            <div class="course-content" style="min-height: 0;">
              <div class="content-header" style="margin-top: 16px; margin-bottom: 0;">
                <h5 class="title">
                  <a href="javascript:" class="title-link">오늘 예정된 학습</a>
                </h5>
              </div>
            </div>
          </article>
        </div>

        <div class="tab-panel is-active">
          <div class="learning-status-wrap">
            <div v-if="todaynowcnt > 0" class="kb-table kb-table-bordered learning-tablelist">
              <table>
                <colgroup>
                  <col style="width:auto" />
                  <col style="width:190px" />
                  <col style="width:auto" />
                  <col style="width:120px" />
                  <col style="width:140px" />
                  <col style="width:162px" />
                </colgroup>
                <tbody>
                <template v-for="(item, index) in nowitems" :key="index">
                  <tr v-if="timestampToDateFormat(item.objBgngDt, 'yyyy.MM.dd') == timestampToDateFormat(new Date().getTime(), 'yyyy.MM.dd')">
                    <td>
                      <div class="item-content">
                        <div class="content-symbol"><i class="symbol-type-gathering"></i></div>
                        <div class="content-details">
                          <h5 class="content-title"><a href="javascript:" class="title-link">{{ item.objNm }}</a></h5>
                          <p class="content-text"><span class="text">비대면집합(NOW)</span></p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div class="item-date">
                        <span>{{ timestampToDateFormat(item.objBgngDt, 'yyyy.MM.dd hh:mm') }} ~ {{ timestampToDateFormat(item.objEndDt, 'yyyy.MM.dd hh:mm') }} </span>
                      </div>
                    </td>
                    <td><div class="item-type"><span class="text"></span></div></td>
                    <td><div class="item-teacher"><span class="text"></span></div></td>
                    <!-- 출석 / 결석 / 응시전 / 응시완료 / 참여전 / 제출전  -->
                    <td><div class="item-status"><span class="text"></span></div></td>
                    <td>
                      <!-- onclass 분기 -->
                      <div v-if="item.lrnObjTyCd === '100008' || item.lrnObjTyCd === '100026'" class="item-actions">
                        <a v-if="((new Date(item.objBgngDt).getTime() - new Date().getTime())/1000/60 < 15) && item.objEndDt > new Date().getTime() && item.objEndYn === 'N'"
                           href="javascript:" class="kb-btn kb-btn-primary" @click="enterNowClass(item)"><strong class="text">입장하기</strong></a>
                        <a v-else-if="item.objBgngDt > new Date().getTime() && item.objEndYn === 'N'"
                           href="javascript:" class="kb-btn kb-btn-primary" @click="enterNowClass(item)" disabled><strong class="text">참여하기</strong></a>
                        <a v-else href="javascript:" class="kb-btn kb-btn-primary" disabled><strong class="text">종료</strong></a>
                      </div>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>

            <div v-else class="contents-empty">
              <img src="@/assets/lxp/images/common/img_empty.png" alt=""/>
              <p class="text">오늘 예정된 학습이 없습니다.<br></p>
              <p v-if="nowbgngdt != '9999.12.31'" class="text"> 학습 예정일 : {{ nowbgngdt }}</p>
            </div>
          </div>
        </div>

        <div class="top-column">&nbsp;</div>

        <template v-if="isReady">
          <div v-for="(item, index) in renderItems" :class="index > 0 ? 'mt-5' : ''" :key="index">
            <ul v-if="items.length > 0" class="educourse-list">
              <li class="list-item">
                <div class="educourse">
                  <div class="educourse-summary course-row" style="border-top: none">
                    <article class="course-card">
                      <div class="course-content" style="min-height: 0;">
                        <div class="content-header" style="margin-top: 16px; margin-bottom: 0;">
                          <h5 class="title">
                            <a href="javascript:" class="title-link">사전과정</a>
                          </h5>
                        </div>
                        <div class="course-total-count">
                          <div class="kb-form-search">
                            <div class="kb-form-search-status">
                              <span class="text">총 {{ items.length }}건</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </article>
                  </div>

                  <div class="educourse-content">
                    <div class="kb-table kb-table-round">
                      <table>
                        <colgroup>
                          <col style="width:100px;">
                          <col style="width:auto;">
                          <col style="width:500px;">
                          <col style="width:160px;">
                        </colgroup>
                        <thead>
                        <tr>
                          <th><span class="th-title">&nbsp;</span></th>
                          <th><span class="th-title">과정차수명</span></th>
                          <th><span class="th-title">연수기간</span></th>
                          <th><span class="th-title">완료여부</span></th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="(item, idx) in items" :key="idx">
                          <tr>
                            <td>
                              <div class="kb-table-image">
                                <img v-if="item.thumb" :src="getThumbUrl(item.thumb)" alt="">
                                <img v-else-if="item.mstThumb" :src="getThumbUrl(item.mstThumb)" alt="">
                                <CourseThumb v-else :num="item.crseMstSn"/>
                              </div>
                            </td>
                            <td>
                              <strong class="td-text kb-mouse-cursor kb-hover-underline"
                                      @click="goLearn(item)">{{ item.crseNm }}</strong>
                            </td>
                            <td><strong class="td-text">
                              {{ timestampToDateFormat(item.bgngDt, 'yyyy.MM.dd') }} ~
                              {{ timestampToDateFormat(item.endDt, 'yyyy.MM.dd') }}</strong></td>
                            <td>
                              <strong v-if="item.splCrseTyCdDcd === '2066015'" class="td-text">-</strong>
                              <strong v-else class="td-text" v-html="getFinishText(item)"></strong>
                            </td>
                          </tr>
                        </template>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </template>

        <template v-if="aplyItems.length > 0">
          <div class="educourse">
            <div class="educourse-summary course-row" style="border-top: none">
              <article class="course-card">
                <div class="course-content" style="min-height: 0;">
                  <div class="content-header" style="margin-top: 16px; margin-bottom: 0;">
                    <h5 class="title">
                      <a href="javascript:" class="title-link">연수과정</a>
                    </h5>
                  </div>
                  <div class="course-total-count">
                    <div class="kb-form-search">
                      <div class="kb-form-search-status">
                        <span class="text">총 {{ aplyItems.length }}건</span>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
          <ul class="educourse-list">
            <li v-for="(item, index) in aplyItems" :key="index" class="list-item">
              <div class="educourse">
                <div class="educourse-summary course-row" style="padding-bottom: 10px;cursor: pointer"
                     @click="collapseOrExpand(index)">
                  <article class="course-card">
                    <div class="course-image">
                      <figure class="image-figure">
                        <img v-if="item.mstThumb" :src="getThumbUrl(item.mstThumb)" alt="">
                        <CourseThumb
                            v-else
                            :num="item.crseMstSn"
                        />
                      </figure>
                    </div>
                    <div class="course-content">
                      <div class="content-header">
                        <h5 class="title">
                          {{ item.crseMstNm }}
                        </h5>
                      </div>
                      <div :class="{'content-footer': isMobile}">
                        <div class="content-meta">
                          <span class="text" :class="{'txt_line' : !isMobile, 'txt_multi' : isMobile}" style="color:var(--kb-gold)">{{ item.crseSumup }}</span>
                        </div>
                        <div class="content-meta" style="margin-top: 20px">
                          <span class="text">
                            {{ item.orgnzNm }}
                            {{ item.mstTotLrnPerid ? ' | '.concat(item.mstTotLrnPerid,'일') : '' }}
                            {{ item.crseMstMlg ? ' | 마일리지'.concat(item.crseMstMlg) : '' }}
                          </span>
                        </div>
                        <div v-if="isMobile" class="striped-list-wrap">
                          <ul class="striped-list">
                            <li class="striped-item">
                              <div class="kb-collapse" :class="{ 'is-active' : !showDists[index] }">
                                <div class="kb-collapse-toggle striped-row" style="background-color: white"
                                     @click="collapseOrExpand(index)">
                                  <div class="striped-column column-arrow" style="padding: 0px 0px;min-height: 0px"><i class="icon-arrow"></i></div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="course-actions" style="display: flex;justify-content: flex-end;flex-direction: column;margin-left: 10px">
                      <div></div>
                      <div v-if="!isMobile" class="striped-list-wrap">
                        <ul class="striped-list">
                          <li class="striped-item">
                            <div class="kb-collapse" :class="{ 'is-active' : !showDists[index] }">
                              <div class="kb-collapse-toggle striped-row" :style="showDists[index] ? {'background-color':'lightgray'} : {}">
                                <div class="striped-column column-arrow"><i class="icon-arrow"></i></div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </article>
                </div>

                <template v-if="showDists[index]">
                  <div v-if="item.crseDists.length > 0" class="educourse-content">
                    <div class="kb-table kb-table-round">
                      <table>
                        <colgroup>
                          <col style="width:auto;">
                          <col style="width:280px;">
                          <col style="width:280px;">
                          <col style="width:150px;">
                          <col style="width:140px;">
                        </colgroup>
                        <thead>
                        <tr>
                          <th><span class="th-title">과정차수명</span></th>
                          <th><span class="th-title">연수기간</span></th>
                          <th><span class="th-title">신청기간</span></th>
                          <th><span class="th-title">정원</span></th>
                          <th><span class="th-title">&nbsp;</span></th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="(dist, idx) in item.crseDists" :key="idx">
                          <tr v-if="isShowApply(dist)" style="cursor: pointer" @click="showApplyView(dist.distCrseSn)">
                            <td><strong class="td-text">{{ dist.crseNm }}</strong></td>
                            <td><strong class="td-text">{{ timestampToDateFormat(dist.bgngDt, 'yyyy.MM.dd') }} ~
                              {{ timestampToDateFormat(dist.endDt, 'yyyy.MM.dd') }}</strong></td>
                            <td>
                              <strong v-if="dist.aplyBgngDt" class="td-text">{{
                                timestampToDateFormat(dist.aplyBgngDt, 'yyyy.MM.dd')
                                }} ~ {{ timestampToDateFormat(dist.aplyEndDt, 'yyyy.MM.dd') }}</strong>
                              <strong v-else class="td-text">-</strong>
                            </td>
                            <td><strong class="td-text">{{
                              dist.peoplCnt > 0 ?
                              `${numberComma(dist.peoplCnt)}명` :
                              '-'
                              }}</strong></td>
                            <td class="text-end">
                              <router-link :to="{name: 'ApplyTrainView', params: {distCrseSn: dist.distCrseSn}}"
                                           class="td-link">
                                <template v-if="limitOver(dist)">
                                  <strong class="td-text" style="color: red">정원초과</strong>
                                </template>
                                <template v-else>
                                  <strong class="td-text">상세</strong><i class="icon-arrow"></i>
                                </template>
                              </router-link>
                            </td>
                          </tr>
                        </template>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </template>
              </div>
            </li>
          </ul>
        </template>

      </div>
    </div>
    <!-- //main-content -->

  </main>
</template>

<script>

import LxpMainHeader from '@/components/common/LxpMainHeader';
import CourseThumb from '@/components/apply/CourseThumb';
import FileDownLink from "@/components/common/FileDownLink";
import {trainDigitalSetup} from '@/assets/js/modules/train/train-digital-setup';

export default {
  name: 'TrainDigital',
  components: {LxpMainHeader, CourseThumb, FileDownLink,},
  setup: trainDigitalSetup
};
</script>

<style scoped>

.lxp-layout .course-row .course-total-count {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.kb-table-image img {
  height: 60px;
  width: 60px;
  border-radius: 16px;
}

</style>