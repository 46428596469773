import {ref, reactive, computed} from 'vue';
import {useAlert} from '@/assets/js/modules/common/alert';
import {
  ACT_GET_CRSE_DIST_DIGITAL_LIST,
  ACT_INSERT_CRSE_DIST_REGULAR,
  ACT_GET_DIGITAL_APPLY_LIST,
  ACT_GET_DIGITAL_NOW_LIST,
  ACT_GET_DIGITAL_DTL,
} from '@/store/modules/course/course';
import {getFunc, getListFunc} from '@/assets/js/ui.init';
import {sortCourseItems} from '@/assets/js/modules/learn/learn-regular-sort';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';
import {commonDownloadFile, isSuccess, numberComma, timestampToDateFormat} from '@/assets/js/util';
import {getThumbUrl} from '@/assets/js/modules/course/course-common';
import navigationUtils from '@/assets/js/navigationUtils';
import {
  ACT_GET_LEARN_HISTORY_LIST
} from '@/store/modules/learn/learn';
import {entranceCloudNowOn, entranceCloudNowOnCheck} from '@/assets/js/modules/cloud/cloud-common';

export const trainDigitalSetup = () => {
  const isMobile = navigationUtils.any();
  const store = useStore();
  const router = useRouter();
  const {showConfirm, showMessage} = useAlert();
  const {showLoading, hideLoading} = useAlert();
  const currentYear = new Date().getFullYear();
  const session = computed(() => store.state.auth.session);

  const isReady = ref(false);
  const statusToggle = ref(false);
  const items = ref([]);
  const nowitems = ref([]);
  const renderItems = ref([]);
  const aplyItems = ref([]);
  const learns = ref([]);
  const dtlItem = reactive({
    lrnerId: 0,
    reqYn: '',
    digiDeptTyDcd: '',
    digiDeptTyNm: '',
    preYn: '',
    basicYn: '',
    advnYn: '',
  });
  const basicLrn = ref('-');
  const advanLrn = ref('-');
  const finalLrn = ref('-');
  const nowbgngdt = ref('9999.12.31');
  const nowbasicbgngdt = ref('');
  const nowadvancedbgngdt = ref('');
  const todaynowcnt = ref('0');
  const isReady2 = ref(false);


  const currentTs = new Date().getTime();
  const showDists = ref([]);
  const showDistsSize = ref(10);
  const hToggle = ref(false);

  const showStatus = () => {
    statusToggle.value = true;
  };
  const showAttachments = ref(false);

  const files =  [
    {
      "fileNm": "기본과정_디지털마인드셋.pdf",
      "upldLoc": "contents/support/기본과정_디지털마인드셋.pdf",
    },
    {
      "fileNm": "기본과정_IT기초용어.pdf",
      "upldLoc": "contents/support/기본과정_IT기초용어.pdf",
    },
    {
      "fileNm": "심화모듈_강의교재.pdf",
      "upldLoc": "contents/support/심화모듈_강의교재.pdf",
    },
    {
      "fileNm": "심화모듈_실습자료.xlsx",
      "upldLoc": "contents/support/심화모듈_실습자료.xlsx",
    }
  ];

  showLoading();

  getListFunc(`course/${ACT_GET_CRSE_DIST_DIGITAL_LIST}`,
      {bgngYmd: `${currentYear}-01-01`, endYmd: `${(currentYear + 1)}-01-01`}, items, null, () => {
        renderItems.value = items.value.map(x => (
            {
              ...x,
              title: '디지털문해력',
              items: sortCourseItems(x.splCrseTyCdDcd, items.value.filter(y => x.splCrseTyCdDcd === y.splCrseTyCdDcd)),
            }
        ));
        hideLoading();
        isReady.value = true;
      });

  getListFunc(`course/${ACT_GET_DIGITAL_APPLY_LIST}`, {}, aplyItems, null, () => {});
  getFunc(`course/${ACT_GET_DIGITAL_DTL}`, {}, dtlItem, () => {});
  getListFunc(`learn/${ACT_GET_LEARN_HISTORY_LIST}`, {}, learns, null, () => {
    if (learns.value.filter(x => x.crseMstSn === 100331 && x.fnshYn === 'Y').length > 0) {
      basicLrn.value = 'Y';
    }
    if (learns.value.filter(x => x.crseMstSn === 100331 && x.fnshYn === 'N').length > 0) {
      basicLrn.value = 'N';
    }
    if (learns.value.filter(x => x.crseMstSn === 100332 && x.fnshYn === 'Y').length > 0) {
      advanLrn.value = 'Y';
    }
    if (learns.value.filter(x => x.crseMstSn === 100332 && x.fnshYn === 'N').length > 0) {
      advanLrn.value = 'N';
    }
    if (learns.value.filter(x => x.crseMstSn === 100334 && x.fnshYn === 'Y').length > 0) {
      finalLrn.value = 'Y';
    }
    if (learns.value.filter(x => x.crseMstSn === 100334 && x.fnshYn === 'N').length > 0) {
      finalLrn.value = 'N';
    }
  });
  getListFunc(`course/${ACT_GET_DIGITAL_NOW_LIST}`, {lrnerId: session.value.lrnerId}, nowitems, null, () => {
    nowbgngdt.value = chknowbgngdt(nowitems.value);
    nowbasicbgngdt.value = chknowbasicbgngdt(nowitems.value);
    nowadvancedbgngdt.value = chknowadvancedbgngdt(nowitems.value);
    todaynowcnt.value = chktodaynowcnt(nowitems.value);
  });

  const isLoading = ref(false);
  const enrollCourse = (item) => {
    if (isLoading.value) return;
    isLoading.value = true;

    store.dispatch(`course/${ACT_INSERT_CRSE_DIST_REGULAR}`, item.distCrseSn).then(res => {
      if (isSuccess(res)) {
        router.push({name: 'LearnRegularMain', params: {distCrseSn: item.distCrseSn}});
      } else {
        showMessage('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
        isLoading.value = false;
      }
    }).catch(e => {
      console.error(e);
      showMessage('오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
      isLoading.value = false;
    });
  };

  const goLearn = (item) => {
    if (item.lrnTrgtGrpDtlSn > 0) {
      router.push({name: 'LearnRegularMain', params: {distCrseSn: item.distCrseSn}});
    } else {
      showConfirm({
        title: item.crseNm,
        text: '해당 연수에 입과하시겠습니까?',
        callback: () => {
          enrollCourse(item);
        },
      });
    }
  };

  const getFinishText = (item) => {
    // 입과 하였을 경우
    if (item.lrnTrgtGrpDtlSn > 0 && item.learns.length > 0) {
      // 콜드콜을 사용하는 경우...
      return item.fnshYn === 'Y' ? '<span class="text-primary">완료</span>' : '<span class="text-muted">미완료</span>';
    }
    return '<span class="text-muted">미완료</span>';
  };

  const getDigitalFinishText = (target, item) => {
    if (target === 'trgt') {
      return item === 'Y' ?
          '<span class="text-primary">대상</span>' :
          '<span class="text-muted">미대상</span>';
    } else {
      return item === 'Y' ?
          '<span class="text-primary">완료</span>' :
          '<span class="text-muted">미완료</span>';
    }
  };

  const limitOver = (dist) => {
    return (dist.peoplCnt > 0 && dist.peoplCnt <= dist.aplyRegCnt);
  };

  const excludeDist = [202333, 202334];
  const isShowApply = (dist) => {
    // 국민은행 영업점 직원은 1, 2 회차 숨김
    if (dtlItem.digiDeptTyDcd === '2112001' && session.value.susdyCdDcd === '2109001') {
      if (excludeDist.includes(dist.distCrseSn)) {
        return false;
      } else {
        // return (!limitOver(dist.peoplCnt, dist.aplyRegCnt) && dist.aplyBgngDt <= currentTs && dist.aplyEndDt > currentTs);
        return (dist.aplyBgngDt <= currentTs && dist.aplyEndDt > currentTs);
      }
    } else {
      // return (!limitOver(dist.peoplCnt, dist.aplyRegCnt) && dist.aplyBgngDt <= currentTs && dist.aplyEndDt > currentTs);
      return (dist.aplyBgngDt <= currentTs && dist.aplyEndDt > currentTs);
    }
  };

  const showApplyViewMobile = (key) => {
    router.push({name: 'ApplyTrainView', params: {distCrseSn: key}});
  };

  const initShow = () => {
    // console.log(props.pageNo);
    showDists.value = [];
    for (let i = 0; i < showDistsSize.value; i++) {
      showDists.value[i] = false;
    }
  }

  const collapseOrExpand = (index) => {
    // console.log(index, showDists.value[index]);
    showDists.value[index] = !showDists.value[index];
    return showDists.value[index];
  }

  initShow();

  const goTest = () => {
    //개발
    // const params = {
    //   distCrseSn : 202258,
    //   objDistSn: 268349,
    //   lrnObjDtlDistSn: 268349,
    //   objType: "100026",
    //   lrnerId: session.value.lrnerId,
    //   etnRefDtl: 'ehrdcloudnow=*|lrnerId|*|53449|student|ETC|NOW|normal'
    // }
    //운영
    const params = {
      distCrseSn : 202332,
      objDistSn: 270006,
      lrnObjDtlDistSn: 270006,
      objType: '100026',
      lrnerId: session.value.lrnerId,
      etnRefDtl: 'ehrdcloudnow=*|lrnerId|*|53449|student|ETC|NOW|normal'
    }

    const sDt = new Date('2023-03-02 00:00:00').getTime();
    const eDt = new Date('2023-06-18 23:59:59').getTime();


    if (sDt > currentTs) {
      showMessage('2023.03.02 부터 학습이 가능합니다.');
    }
    else if (eDt < currentTs) {
      showMessage('사전테스트 기간이 6월 18일부로 종료되었습니다.');
    }
    else {
      entranceCloudNowOnCheck(params);
    }
  }

  const enterNowClass = (item) => {
    const params = {
      distCrseSn : item.distCrseSn,
      objDistSn: item.lrnObjDtlDistSn,
      lrnObjDtlDistSn: item.lrnObjDtlDistSn,
      objType: item.lrnObjTyCd,
      lrnerId: session.value.lrnerId,
      etnRefDtl: item.etnRefDtl
    }
    //console.log(toString(params));
    entranceCloudNowOnCheck(params)
  }


  const headerToggle = () => {
    if (hToggle.value) {
      hToggle.value = false;
    } else {
      hToggle.value = true;
    }
  };

  const showApplyView = (index) => {
    // console.log(index);
    router.push({name: 'ApplyTrainView', params: {distCrseSn: index}});
  }

  const downloadAtch = (atch) => {
    commonDownloadFile(atch);
  };

  const chktodaynowcnt = (item) => {
    let ret = 0;
    if (item) {
      for (let i = 0; i < item.length; i++) {
        if (timestampToDateFormat(item[i].objBgngDt, 'yyyy.MM.dd') == timestampToDateFormat(new Date().getTime(), 'yyyy.MM.dd')) {
          ret++;
        }
      }
    }
    return ret
  }

  const chknowbgngdt = (item) => {
    let ret = '9999.12.31'
    for (let i = 0; i < item.length; i++){
      if (timestampToDateFormat(item[i].objBgngDt, 'yyyy.MM.dd') < ret && timestampToDateFormat(item[i].objBgngDt, 'yyyy.MM.dd') >= timestampToDateFormat(new Date().getTime(), 'yyyy.MM.dd') ) {
        ret = timestampToDateFormat(item[i].objBgngDt, 'yyyy.MM.dd');
      }
    }
    return ret;
  }

  const chknowbasicbgngdt = (item) => {
    let ret = '';
    for (let i = 0; i < item.length; i++){
      if (item[i].crseMstSn == '100331' && timestampToDateFormat(item[i].objBgngDt, 'yyyy.MM.dd') > timestampToDateFormat(new Date().getTime(), 'yyyy.MM.dd')) {
        return timestampToDateFormat(item[i].objBgngDt, 'yyyy.MM.dd');
      }
    }
    return ret;
  }

  const chknowadvancedbgngdt = (item) => {
    let ret = ''
    for (let i = 0; i < item.length; i++){
      if (item[i].crseMstSn == '100332' && timestampToDateFormat(item[i].objBgngDt, 'yyyy.MM.dd') > timestampToDateFormat(new Date().getTime(), 'yyyy.MM.dd')) {
        return timestampToDateFormat(item[i].objBgngDt, 'yyyy.MM.dd');
      }
    }
    return ret;
  }

  return {
    isReady,
    isReady2,
    statusToggle,
    items,
    nowitems,
    renderItems,
    showStatus,
    aplyItems,
    dtlItem,
    learns,
    basicLrn,
    advanLrn,
    finalLrn,
    session,
    nowbgngdt,
    nowbasicbgngdt,
    nowadvancedbgngdt,
    todaynowcnt,

    goLearn,
    timestampToDateFormat,
    numberComma,
    getThumbUrl,
    getFinishText,
    getDigitalFinishText,
    limitOver,

    isMobile,
    isShowApply,
    showApplyViewMobile,
    collapseOrExpand,
    showDists,
    guideFileKey: 'TrainDigitalGuide',
    mngrFileKey: 'TrainDigitalManager',
    goTest,
    enterNowClass,
    hToggle,
    headerToggle,
    showAttachments,
    files,
    downloadAtch,
    showApplyView,
    entranceCloudNowOn,
    chktodaynowcnt,
    chknowadvancedbgngdt
  };
}
